
import Vue from "vue";
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer.vue";
import SignUpInfo from "@/components/SignUp/SignUpInfo.vue";

export default Vue.extend({
  name: "SignUpPrivacyPage",
  components: {
    Header,
    SignUpInfo,
    Footer
  }
});
